<template>
  <v-autocomplete
    v-model="personel"
    :items="personeller"
    chips
    hide-details
    :label="label"
    item-text="name"
    item-value="id"
    :menu-props="{contentClass:'list-style'}"
    multiple
  >
    <template #selection="data">
      <v-chip
        v-bind="data.attrs"
        :input-value="data.selected"
        close
        dense
        label
        @click="data.select"
        @click:close="Remove(data.item)"
      >
        <v-avatar left>
          <v-img :src="`/img/${data.item.avatar}`"></v-img>
        </v-avatar>
        {{ data.item.name }}
      </v-chip>
    </template>

    <template #item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>

      <template v-else>
        <v-list-item-avatar>
          <v-img :src="`/img/${data.item.avatar}`"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ data.item.name }}
          </v-list-item-title>
          <v-list-item-subtitle>
            {{ data.item.group }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  name: 'PersonelSelect',
  props: ['label'],
  computed: {
    personeller() {
      return this.$store.state.Personeller.PersonelList
    },
    personel: {
      set(id) {
        this.$store.commit('Personeller/SelectPersonel', id)
      },
      get() {
        return this.$store.state.Personeller.SelectPersonel
      },

    },
  },
  mounted() {
    this.$store.dispatch('Action', { name: 'Personeller/PersonellerAutocomplate' })
  },
  methods: {
    Remove(item) {
      const index = this.personel.findIndex(elem => item.id === elem)
      this.personel.splice(index, 1)
    },
  },
}
</script>
